import React from 'react'
import Layout from '../components/Layout'
import Legal from '../components/Legal/TextContent'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Cookie Policy',
    url: '/cookie-policy',
  },
]

const tableHeaderStyle = {
  background: 'lightgrey',
  textAlign: 'left',
  padding: '5px',
  width: '20%',
  borderBottom: '1px solid #ccc',
}

const tableHeaderStyleWide = {
  background: 'lightgrey',
  textAlign: 'left',
  padding: '5px',
  width: '40%',
  borderBottom: '1px solid #ccc',
}

const tableColumnStyle = {
  textAlign: 'left',
  padding: '5px',
  width: '20%',
  borderBottom: '1px solid #ccc',
}

const wideColumnStyle = {
  ...tableColumnStyle,
  width: '40%',

}

const thinBorder = {
  border: '1px solid #ccc',
}

const Privacy = () => {
  return (
    <Layout
      activeLink="/cookie-policy"
      title="R&D Cookie Policy"
      description="R&D Cookie Policy"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <Legal
        heading="Cookie Policy"
        content={
          <div>
            <p>
              To provide the best experiences, we use technologies like cookies
              to store and/or access device information. Consenting to these
              technologies will allow us and our partners to process personal
              data such as browsing behavior or unique IDs on this site and show
              (non-) personalized ads. Not consenting or withdrawing consent,
              may adversely affect certain features and functions.
            </p>
            <p>
              Click below to consent to the above or make granular choices. Your
              choices will be applied to this site only. You can change your
              settings at any time, including withdrawing your consent, by using
              the toggles on the Cookie Policy, or by clicking on the manage
              consent button at the bottom of the screen.
            </p>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ ...tableHeaderStyle, ...thinBorder }}>Cookie Name</th>
                  <th style={{ ...tableHeaderStyle, ...thinBorder }}>Cookie Type</th>
                  <th style={{ ...tableHeaderStyleWide, ...thinBorder }}>Function</th>
                  <th style={{ ...tableHeaderStyle, ...thinBorder }}>Retention</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>__Secure-3PSIDCC</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Advertising</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    Google Analytics - To create a user profile and display
                    relevant and personalized Google ads
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>2 Years</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>__Secure-3PSIDTS</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Advertising</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    Google Analytics – collects information about your
                    interactions with Google services and ads
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>2 Years</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>__Secure-1PSIDTS</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Advertising</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    Google Analytics – to collect information about your
                    interactions with Google services and ads. Contains a unique
                    identifier.
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>1 Year</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>__Secure-1PSIDCC</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Functional</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    Necessary for the use of options and services of the
                    website
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>2 Years</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>SIDCC</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Functional</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    To provide the identification of trusted web traffic
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>1 Year</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>__Secure-3PAPISID</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Advertising</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    Google Analytics – To deliver ads more relevant to you and
                    your interests
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>2 Years</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>SSID</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Advertising</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    Google Analytics - To collect user information for videos
                    hosted by YouTube
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>2 Years</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>__Secure-1PAPISID</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Functional</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    Necessary for the use of options and services of the
                    website
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>2 Years</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>HSID</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Functional</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    To provide fraud prevention
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>2 Years</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>__Secure-1PSID</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Functional</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>Required for the use of website options and services.</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>2 Years</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>SID</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Advertising</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    Google Analytics - To provide ad delivery or retargeting,
                    provide fraud prevention
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>2 Years</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>__Secure-3PSID</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Advertising</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    Google Analytics – To deliver ads more relevant to you and
                    your interests
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>2 Years</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>SAPISID</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Advertising</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    Google Analytics - To display personalized ads based on
                    recent searches and enable Google to collect user information
                    for videos hosted by YouTube
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>2 Years</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>APISID</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Advertising</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    Google Analytics - To collect user information for videos
                    hosted by YouTube
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>2 Years</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>_ga</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Statistics</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    Google Analytics – To store and count page views
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>2 Years</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>cookiesAccepted</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Functional</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>To record cookie preferences</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Persistent</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>_ga_74Q1RR16WT</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Tracking</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>Google analytics – To create a unique user ID</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>1 Year</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>NID</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Advertising</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    Google Analytics - To provide ad delivery or retargeting,
                    store user preferences
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>6 Months</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>CONSENT</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Functional</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>To store cookie consent preferences</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>20 Years</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>SOCS</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Functional</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>To store a user’s state regarding their cookie choices</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>24 Hours</td>
                </tr>
                <tr>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>AEC</td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>Functional</td>
                  <td style={{ ...wideColumnStyle, ...thinBorder }}>
                    To ensure that requests within a browsing session are made
                    by the user, and not by other sites. These cookies prevent
                    malicious sites from acting on behalf of a user without that
                    user’s knowledge
                  </td>
                  <td style={{ ...tableColumnStyle, ...thinBorder }}>6 Months</td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      />
    </Layout>
  )
}

export default Privacy
